@font-face {
  font-family: 'Helvetica Neue LT Std H';
  src: url('HelveticaNeueLTStd-Hv.eot');
  src: url('HelveticaNeueLTStd-Hv.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueLTStd-Hv.woff2') format('woff2'), url('HelveticaNeueLTStd-Hv.woff') format('woff'),
    url('HelveticaNeueLTStd-Hv.ttf') format('truetype'),
    url('HelveticaNeueLTStd-Hv.svg#HelveticaNeueLTStd-Hv') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Std R';
  src: url('HelveticaNeueLTStd-Roman.eot');
  src: url('HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueLTStd-Roman.woff2') format('woff2'), url('HelveticaNeueLTStd-Roman.woff') format('woff'),
    url('HelveticaNeueLTStd-Roman.ttf') format('truetype'),
    url('HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Std L';
  src: url('HelveticaNeueLTStd-Lt.eot');
  src: url('HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueLTStd-Lt.woff2') format('woff2'), url('HelveticaNeueLTStd-Lt.woff') format('woff'),
    url('HelveticaNeueLTStd-Lt.ttf') format('truetype'),
    url('HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Std B';
  src: url('HelveticaNeueLTStd-Bd.eot');
  src: url('HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueLTStd-Bd.woff2') format('woff2'), url('HelveticaNeueLTStd-Bd.woff') format('woff'),
    url('HelveticaNeueLTStd-Bd.ttf') format('truetype'),
    url('HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Std M';
  src: url('HelveticaNeueLTStd-Md.eot');
  src: url('HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueLTStd-Md.woff2') format('woff2'), url('HelveticaNeueLTStd-Md.woff') format('woff'),
    url('HelveticaNeueLTStd-Md.ttf') format('truetype'),
    url('HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md') format('svg');
  font-weight: 500;
  font-style: normal;
}
